import firebase from "firebase";

require("firebase/firestore");
require("firebase/storage");
require("firebase/auth");

// Initialize Firebase

const config = {
  apiKey: "AIzaSyB5M1wsDRve18-ZlEStkeOad_FKQNTwEQc",
  authDomain: "mail-b6c55.firebaseapp.com",
  projectId: "mail-b6c55",
  storageBucket: "mail-b6c55.appspot.com",
  messagingSenderId: "204389445806",
  appId: "1:204389445806:web:d45ccd560691ef804dbd3f",
};

firebase.initializeApp(config);
const fstore = firebase.storage();
var sRef = fstore.ref();

export const fb = firebase;
export const db = fb.database();
export const fbStore = fstore;
export const auth = firebase.auth();
export const storageRef = sRef;
export const fireStore = firebase.firestore();
